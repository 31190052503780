import { useState, useContext, useEffect, useCallback } from "react";
import { IconRefresh } from "@tabler/icons";
import {
  Button,
  IconButton,
  MenuItem,
  Typography,
  Select,
  useMediaQuery
} from "@mui/material";
import * as React from "react";
import MainModal from "../../../../ui-component/modals/MainModal";
import { buscarEmpresaPorRuc } from "../../../../services/Empresa";
import { modeContext } from '../../../../context/modeContext';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const baseURL = process.env.PUBLIC_URL;

const EmpresaSecion = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); 

  const [value, setValue] = useState('');
  const [empresas, setEmpresas] = useState([]);
  const [openModalSeleccionaEmpresa, setOpenModalSeleccionaEmpresa] = useState(false);

  const { setCompany, company, token, setTokenCompany } = useContext(modeContext);
  const navigate = useNavigate();

  const loadEmpresas = useCallback(() => {
    if (token) {
      setOpenModalSeleccionaEmpresa(true);
      fetch(`${process.env.REACT_APP_API}business/api/usuario/empresas`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
          cache: 'no-cache',
          pragma: 'no-cache',
          'cache-control': 'no-cache'
        }
      })
        .then(res => res.ok ? res.json() : navigate(`${baseURL}/security/logout`))
        .then(lista_empresa => setEmpresas(lista_empresa))
        .catch(error => console.error("Error al cargar empresas:", error));
    }
  }, [token, navigate]);

  useEffect(() => {
    if (!company) {
      loadEmpresas();
    }
  }, [company, loadEmpresas]);

  return (
    <>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: isMobile ? "center" : "flex-start",
        marginLeft: isMobile ? 0 : 20,
        padding: isMobile ? "10px" : "20px"
      }}>
        <Typography
          id="modal-find-worker"
          variant={isMobile ? "h5" : "h2"}
          component="span"
          sx={{
            color: theme.palette.primary.main,
            textAlign: isMobile ? "center" : "left"
          }}>
          {company?.razon_social || 'Seleccione Empresa'}
        </Typography>
        <IconButton
          aria-label="refresh"
          size="small"
          color="primary"
          sx={{ ml: isMobile ? 1 : 2 }}
          onClick={loadEmpresas}
        >
          <IconRefresh />
        </IconButton>
      </div>

      <MainModal
        open={openModalSeleccionaEmpresa}
        onClose={() => { return false }}
        aria_labelledby="modal-find-worker"
        aria_describedby="modal-find-pick-worker"
        styleBody={{
          width: isMobile ? '90%' : '30%',
          textAlign: 'center',
          padding: "20px"
        }}
      >
        <Typography id="modal-find-worker" variant="h4" component="h2">
          Selecciona la empresa
        </Typography>
        <Select
          sx={{ marginTop: "1rem", width: "100%" }}
          value={value}
          size="small"
          onChange={(event) => setValue(event.target.value)}
        >
          <MenuItem value="">-- Seleccione --</MenuItem>
          {empresas.map((empresa, index) => (
            <MenuItem key={index} value={empresa.ruc}>{empresa.razon_social}</MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: "1rem", width: "100%" }}
          onClick={async () => {
            if (!value.trim()) return;
            const empresa = empresas.find(empresa => empresa.ruc === value);
            const tokenEmpresa = await buscarEmpresaPorRuc(empresa.ruc, token);
            setOpenModalSeleccionaEmpresa(false);
            setCompany(empresa);
            setTokenCompany(tokenEmpresa);
            localStorage.setItem('tokenCompany', tokenEmpresa);
            localStorage.setItem('company', JSON.stringify(empresa));
          }}
        >
          Ingresar
        </Button>
      </MainModal>
    </>
  );
};

export default EmpresaSecion;
