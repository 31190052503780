import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  useMediaQuery
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import LogoSection from "../LogoSection";
import MenuList from "./MenuList";
import { drawerWidth, drawerWidthClose } from "../../../store/constant";

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("xs")); 

  const [openMenu, setOpenMenu] = useState(null);

  const handleMenuClick = (menuId) => {
    setOpenMenu((prevOpenMenu) => (prevOpenMenu === menuId ? null : menuId));
  };

  useEffect(() => {}, [isMobile]);

  const container = window !== undefined ? () => window.document.body : undefined;

  const drawerContent = (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
          <LogoSection />
        </Box>
      </Box>

      {isMobile ? (
        <Box
          sx={{
            px: 2,
            backgroundColor: theme.palette.background.default,
            height: "100vh",
            overflowY: "auto",
            display: "block",
            color: theme.palette.text.primary,
          }}
        >
          <MenuList
            drawerOpen={drawerOpen}
            openMenu={openMenu}
            handleMenuClick={handleMenuClick}
            drawerToggle={drawerToggle}
            isMobile={isMobile}
          />
        </Box>
      ) : (
        <PerfectScrollbar
          component="div"
          style={{
            height: "calc(100vh - 88px)",
            paddingLeft: "13px",
            paddingRight: "14px",
            backgroundColor: "#000064",
          }}
        >
          <MenuList
            drawerOpen={drawerOpen}
            openMenu={openMenu}
            handleMenuClick={handleMenuClick}
            isMobile={isMobile}
          />
        </PerfectScrollbar>
      )}
    </>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: drawerOpen ? drawerWidth : drawerWidthClose,
      }}
      aria-label="sidebar"
    >
      <Drawer
        container={container}
        disablePortal
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "88px",
            },
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
